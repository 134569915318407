<template>
  <v-form ref="formRef" validate-on="blur lazy">
    <v-row>
      <v-col cols="12">
        <v-select
          v-model="feedbackTypeModel"
          label="* Feedback type"
          :items="[
            // Object.values(FEEDBACK_TYPES),
            FEEDBACK_TYPES.bug,
            FEEDBACK_TYPES.newFeature,
            FEEDBACK_TYPES.improvement,
            FEEDBACK_TYPES.growth,
            FEEDBACK_TYPES.accessibility,
            FEEDBACK_TYPES.legal,
            FEEDBACK_TYPES.seo,
            FEEDBACK_TYPES.security,
            FEEDBACK_TYPES.soc2,
          ]"
          :rules="[requiredRule]"
          required
          hide-details
        />
      </v-col>

      <v-col cols="12">
        <div class="mb-3">
          <v-btn
            :prepend-icon="isRecording ? mdiRecordCircle : undefined"
            class="me-3 text-transform-none"
            @click="onStartRecording"
          >
            Voice to text
          </v-btn>
          <v-btn
            v-show="isRecording"
            :prepend-icon="mdiStop"
            class="me-3 text-transform-none"
            @click="stopRecording"
          >
            Stop recording
          </v-btn>
        </div>

        <v-textarea
          v-model="descriptionModel"
          label="* Describe your feedback"
          required
          :rules="[requiredRule]"
          rows="4"
        />
      </v-col>

      <v-col cols="12">
        <v-file-input
          v-model="filesModel"
          chips
          :accept="supportedFileTypes.join(', ')"
          label="Screenshots"
          clearable
          :prepend-icon="mdiImage"
          :loading="props.isUploadingFile"
          show-size
          counter
          :rules="props.fileRules"
          multiple
          hint="Please remove any sensitive data from images before uploading, to protect your privacy."
          persistent-hint
          validate-on="input"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script setup>
import { FEEDBACK_TYPES, supportedFileTypes } from '@/constants/feedback';
import { mdiImage, mdiRecordCircle, mdiStop } from '@mdi/js';

const props = defineProps({
  isUploadingFile: {
    type: Boolean,
    required: false,
  },
  fileRules: {
    type: Array,
    required: true,
    default: () => [],
    // TODO: Add validation
  },
});

// TODO: Add validation
const feedbackTypeModel = defineModel('feedbackType', {
  type: String,
  required: false,
});
// TODO: Add validation
const descriptionModel = defineModel('description', {
  type: String,
  required: false,
});
// TODO: Add validation
const filesModel = defineModel('files', { type: Array, required: false });

const { resultText, isRecording, startRecording, stopRecording } =
  useSpeechRecognition();

const onStartRecording = () => {
  if (
    descriptionModel.value ===
    FEEDBACK_TYPES[feedbackTypeModel.value]?.feedbackDescription
  ) {
    descriptionModel.value = '';
  }
  startRecording();
};

watch(
  () => resultText.value,
  (newText) => {
    descriptionModel.value += newText;
  },
);

watch(
  () => feedbackTypeModel.value,
  (newFeedbackType) => {
    descriptionModel.value =
      FEEDBACK_TYPES[newFeedbackType].feedbackDescription;
  },
);

const formRef = ref();

const validateForm = async () => {
  const validate = await formRef.value.validate();
  return validate?.valid;
};

defineExpose({
  validateForm,
});
</script>

<style scoped></style>
