<template>
  <div>
    <v-dialog
      v-model="showDialog"
      :max-width="showFullScreen ? '90%' : '70%'"
      z-index="9999999999"
      transition="dialog-bottom-transition"
      :fullscreen="showFullScreen"
    >
      <template #activator="{ props: activatorProps }">
        <slot name="activator-slot" v-bind="activatorProps"></slot>
      </template>
      <template #default="{ isActive }">
        <v-card v-if="isActive" title="Give us feedback">
          <template #append>
            <v-icon
              aria-label="Close Dialog"
              icon="$close"
              @click="isActive.value = false"
            />
          </template>
          <v-card-text rounded="lg" class="pt-0">
            <v-stepper
              v-model="step"
              alt-labels
              color="primary"
              selected-class="selected"
              flat
            >
              <v-stepper-header flat>
                <StepperItem
                  title="Share feedback"
                  :item-step="1"
                  :current-step="step"
                  class="pt-4"
                />
                <v-col class="px-0">
                  <v-progress-linear
                    :model-value="step === 1 ? 50 : 100"
                    :color="step > 1 ? 'primary-darken-2' : 'info-darken-1'"
                    rounded-bar
                    rounded="shaped"
                    aria-label="Wizard progress 1st phase"
                  />
                </v-col>
                <StepperItem
                  title="Confirm conditions"
                  :item-step="2"
                  :current-step="step"
                  class="pt-4"
                />
              </v-stepper-header>
              <v-stepper-window>
                <FirstStep
                  v-if="step === 1"
                  ref="FirstStepRef"
                  v-model:feedbackType="formAnswers.feedbackType"
                  v-model:description="formAnswers.description"
                  v-model:files="filesToUpload"
                  :is-uploading-file="isUploadingFile"
                  :file-rules="fileRules"
                />
                <LastStep
                  v-else-if="step === 2"
                  ref="LastStepRef"
                  v-model:user="formAnswers.user"
                  v-model:isAnonymous="formAnswers.isAnonymous"
                  v-model:acceptTermsOfService="
                    formAnswers.acceptTermsOfService
                  "
                />
              </v-stepper-window>

              <template #actions="{ prev, next }">
                <v-container>
                  <v-row class="pb-8">
                    <v-col v-if="step > 1">
                      <v-btn
                        class="w-100"
                        :prepend-icon="mdiArrowLeft"
                        @click="prev"
                      >
                        Previous
                      </v-btn>
                    </v-col>
                    <v-col sm="6" xs="12" class="ms-auto me-0 text-end">
                      <v-btn
                        :append-icon="mdiArrowRight"
                        color="info"
                        class="w-100"
                        :loading="isSubmitting"
                        @click="onNext(next)"
                      >
                        {{ step < 2 ? 'Next' : 'Submit' }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-stepper>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
    <Snackbar
      v-model="showSnackbar"
      :content="snackbarContent"
      :type="snackbarType"
    />
  </div>
</template>

<script setup>
import { mdiArrowRight, mdiArrowLeft } from '@mdi/js';
import {
  APP_TYPES,
  MAX_FILE_SIZE_MB,
  MAX_FILES_ALLOWED,
  supportedFileTypes,
  feedbackFormAnswers,
} from '@/constants/feedback';
import StepperItem from '@/components/Feedback/StepperItem.vue';
import FirstStep from '@/components/Feedback/internal/FirstStep.vue';

import useSubmitFeedbackForm from '@/components/Feedback/useSubmitFeedbackForm';
import {
  fileSizeLessThanXMb,
  isFileTypeSupported,
  isWithinMaxFilesLimit,
} from '@/utils/validations';

const LastStep = defineAsyncComponent(
  () => import('@/components/Feedback/steps/LastStep.vue'),
);

defineOptions({
  name: `FeedbackOverlay`,
});

const showDialog = ref(false);

const step = ref(1);

const formAnswers = ref(feedbackFormAnswers());

const FirstStepRef = ref();
const LastStepRef = ref();

const onNext = async (nextFn) => {
  let isValid;
  switch (step.value) {
    case 1:
      isValid = await FirstStepRef.value?.validateForm();
      if (isValid) {
        return nextFn();
      }
      break;
    case 2:
      isValid = await LastStepRef.value?.validateForm();
      if (isValid) {
        return onSubmitForm();
      }
      break;
    default:
      break;
  }
  showError(`You have some errors, check the fields and fix them`);
};

const fileRules = [
  fileSizeLessThanXMb(MAX_FILE_SIZE_MB),
  isFileTypeSupported(supportedFileTypes),
  isWithinMaxFilesLimit(MAX_FILES_ALLOWED),
];

const { showSnackbar, snackbarType, snackbarContent, showError, showSuccess } =
  useSnackbar();

const {
  filesToUpload,
  isUploadingFile,
  associateUploadedFileWithFeedbackId,
  uploadedFilesIds,
} = useFileUpload({ fileRules, showError });

const resetForm = () => {
  formAnswers.value = feedbackFormAnswers();
  step.value = 1;
  // Emptying it since it's new form
  // And also so we don't send a delete request
  uploadedFilesIds.value = [];
};

const { submitForm, isSubmitting } = useSubmitFeedbackForm({
  showError,
  associateUploadedFileWithFeedbackId,
});

const url = ref(``);
const showFullScreen = ref();

const shouldShowFullscreen = () => {
  showFullScreen.value =
    document.documentElement.clientWidth < 600 ? true : false;
};

onMounted(() => {
  url.value = window.location.href;
  shouldShowFullscreen();
});

const onSubmitForm = async () => {
  const { user, ...rest } = formAnswers.value;
  const submission = await submitForm({
    ...user,
    ...rest,
    FeedbackUrl: url.value,
    app: {
      storeId: null,
      type: APP_TYPES.web.value,
      url: 'https://users-driven.com',
      name: `Users Driven`,
    },
  });
  if (submission) {
    showSuccess(
      `Congrats! you just submitted an amazing feedback, Thank you! We'll keep you updated regarding the same.`,
    );
    setTimeout(() => {
      showDialog.value = false;
      resetForm();
    }, 2000);
  }
};
</script>

<style scoped>
.v-stepper-item {
  padding-bottom: 4px;
}

:deep(.stepper-item-title) {
  margin-bottom: 4px !important;
}
</style>
